/* eslint-disable prettier/prettier */
/* eslint-disable no-undef */
import axios from "axios";

const state = {
  data: "",
  isAuthorized: false,
  RechazarModal: false,
  ApproveModal: false,
  EditModal: false,
  openModal: false,
  personas: [],
  Messege: "",
  wasPosted: false,
  userRegister: "",
  canales: [],
};

const getters = {
  User: (state) => state.data,
  isAuthorized: (state) => state.isAuthorized,
  openModal: (state) => state.openModal,
  GetRechazarModal: (state) => state.RechazarModal,
  GetApproveModal: (state) => state.ApproveModal,
  GetEditModal: (state) => state.EditModal,
  Personas: (state) => state.personas,
  isPosted: (state) => state.wasPosted,
  errormsg: (state) => state.Messege,
  errorUser: (state) => state.userRegister,
  Canales: (state) => state.canales,
};

const mutations = {
  SET_ERROR_USER(state, data) {
    state.userRegister = data;
  },
  Rechazar(state) {
    state.data.estado = "REC";
  },
  PassUser(state, item) {
    state.data = item;
  },
  SET_PERSONAS(state, personas) {
    personas.sort((a, b) => {
      return b.fecha_creacion - a.fecha_creacion;
    });
    state.personas = personas;
  },

  isAuthorized(state, NEWisAuthorized) {
    state.isAuthorized = NEWisAuthorized;
  },

  openModal(state, newopenModal) {
    state.openModal = newopenModal;
  },

  RechazarModal(state) {
    state.RechazarModal = !state.RechazarModal;
  },
  ApproveModal(state) {
    state.ApproveModal = !state.ApproveModal;
  },
  EditModal(state) {
    state.EditModal = !state.EditModal;
  },
  POST_ERROR(state, messege) {
    state.Messege = messege;
    state.wasPosted = !state.wasPosted;
    setTimeout(function () {
      state.wasPosted = !state.wasPosted;
      state.Messege = "";
    }, 2000);
  },
  SET_CANALES(state, canales) {
    state.canales = canales;
  },
};

export const actions = {
  async GetAllUsers({ commit }, params = { limit: 10, page: 1 }) {
    return await axios({
      url: "solicitudes",
      method: "GET",
      params,
    })
      .then((response) => {
        const personas = response.data.data.lista_solicitud || [];
        const pagination = response.data.data.pagination || {};
        commit("SET_PERSONAS", personas);
        return { personas, pagination };
      })
      .catch(() => {});
  },

  Openusermodal({ commit }, item) {
    commit("PassUser", item);
    commit("usermodal", item);
  },
  EditModal({ commit }, item) {
    commit("PassUser", item);
    commit("EditModal", item);
  },
  RechazarModal({ commit }, item) {
    commit("PassUser", item);
    commit("RechazarModal", item);
  },
  ApproveModal({ commit }, item) {
    commit("PassUser", item);
    commit("ApproveModal", item);
  },

  ChangeUserStatus: async function ({ commit }, solicitation) {
    commit("loadingstatus", true);
    return new Promise((resolve, reject) => {
      axios({
        url: `solicitudes/usuarios/${solicitation.solicitud}`,
        method: "PUT",
        data: {
          estado: `${solicitation.estado}`,
          motivo_rechazo: `${solicitation.motivo}`,
          canal: `${solicitation.canal}`,
          sucursal: `${solicitation.sucursal}`,
          aprobado_por: `${solicitation.aprobado_por}`,
        },
      })
        .then((res) => {
          resolve(res.data.data);

          if (solicitation.estado === "A") {
            commit("loadingstatus", false);
            commit("usermodal", false);
            commit("openModal", true);
            commit("isAuthorized", true);
          } else if (solicitation.estado === "REC") {
            commit("loadingstatus", false);
            commit("openModal", true);
            commit("isAuthorized", false);
            commit("Rechazar");
          }
        })
        .catch((err) => {
          commit("loadingstatus", false);
          reject(err);
        });
    });
  },

  CloseUserModal({ commit }) {
    commit("usermodal");
  },

  CloseFinalModal({ commit }) {
    commit("openModal", false);
  },

  // POST NUEVO USUARIO DESDE TAREAS
  PostNewUser({ commit }, Form) {
    commit("loadingstatus", true);

    axios({
      url: "solicitudes/usuarios",
      method: "POST",
      data: {
        nombre: Form.Name,
        primer_apellido: Form.Nickname,
        segundo_apellido: Form.secondNickname,
        celular: Form.phone,
        correo_electronico: Form.address,
        canal: Form.canal,
        sucursal: Form.cursal,
        usuario: Form.Rut,
        clave: Form.password,
        convenio: this.$store.getters.url,
        origen_solicitud: "formulario inicio",
      },
    })
      .then(() => {
        commit("loadingstatus", false);
        commit("usermodal", true);
      })
      .catch(() => {
        commit("loadingstatus", false);
      });
  },
  PostNewUserPlatform({ commit }, Form) {
    commit("loadingstatus", true);

    axios
      .post(
        "/tareas/usuarios",

        {
          nombre: Form.nombre,
          primer_apellido: Form.primer_apellido,
          segundo_apellido: Form.segundo_apellido,
          celular: Form.celular,
          correo_electronico: Form.correo_electronico,
          canal: Form.canal,
          sucursal: Form.sucursal,
          usuario: Form.Rut,

          convenio: Form.Convenio,
          origen_solicitud: "tareas personas",
        }
      )
      .then(() => {
        commit("loadingstatus", false);
        commit("usermodal", true);
      })
      .catch((err) => {
        commit(
          "SET_ERROR_USER",
          err.response.data.data.lista_mensaje[0].mensaje
        );

        commit("loadingstatus", false);

        setTimeout(() => {
          commit("SET_ERROR_USER", "");
        }, 1500);
      });
  },
  /* async GetCanalesConvenio({ commit }) {
    await axios({
      url: "convenios/listar",
      method: "GET",
    })
      .then((response) => {
        const canales = response.data.data.lista_canal;
        commit("SET_CANALES", canales);
      })
      .catch(() => {});
  }, */
  GetCanalesConvenio: async function ({ commit }) {
    commit("loadingstatus", true);
    return new Promise((resolve, reject) => {
      axios({
        url: "convenios/listar",
        method: "GET",
      })
        .then((res) => {
          resolve(
            res.data.data.lista_canal.sort((a, b) =>
              a.canal_descripcion.localeCompare(b.canal_descripcion)
            )
          );
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
