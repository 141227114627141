/* eslint-disable no-empty-pattern */
import axios from "axios";
import {
  // requestImage,
  // companyLogoName,
  // companyNumber,
  companyLogoUrl,
  // defaultCompanyUrl,
  defaultLogoPropName,
} from "@/services/file.js";

const URL_API_NEGOCIO = process.env.VUE_APP_BASE_URL_FUNCIONAL_NEGOCIO;
const URL_BFF = process.env.VUE_APP_BASE_URL;

const labelComuna = "Comuna";
const mpayError =
  "Ha ocurrido un error al intentar conectar con el servicio de MESOSPay";

export const actions = {
  getLocations({ commit }) {
    return new Promise((resolve, reject) => {
      axios({
        url: "localidades/comunas",
        method: "GET",
      })
        .then((res) => {
          const comunas = [];
          res.data.data.comunas.map((it) => {
            it.comunas.map((com) => {
              comunas.push({
                label: com.comuna_descripcion,
                value: com.comuna,
              });
            });
          });

          const cities = {
            label: labelComuna,
            answers: comunas,
            selected: "",
            placeholder: labelComuna,
          };
          const citiesTwo = {
            label: labelComuna,
            answers: comunas,
            selected: "",
            placeholder: labelComuna,
            rules: [],
          };
          commit("setCities", cities);
          commit("setCitiesTwo", citiesTwo);

          resolve(comunas);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  setProposalStep({ commit }, payload) {
    // Payload => id: nro de propuesta, step: nro de paso del formulario
    const path = `/propuestas/${payload.id}/step`,
      formData = { step: payload?.step || 1 };
    return new Promise((resolve, reject) => {
      axios
        .post(path, formData)
        .then(() => {
          commit("changeProposalStep", payload.step);
          resolve("ok");
        })
        .catch(() =>
          reject(
            new Error("No se ha logrado actualizar el proceso de la propuesta")
          )
        );
    });
  },

  getPayments({ commit }, payload) {
    commit("setCreating", true);
    return new Promise((resolve, reject) => {
      axios({
        url: `propuestas/RUT/${payload.clientRut}/${payload.proposalNumber}/medio-pagos`,
        method: "GET",
      })
        .then(async (res) => {
          const result = res.data.data.medios_pago;
          // Filtrar los medios de pago Contado y Objetos vacios
          const newPayments = result.filter(
            (e) => e.codigo && e.codigo !== "C"
          );
          // Agregar la propiedad loading a cada elemento
          newPayments.forEach((element) => (element.loading = false));
          newPayments.push({ type: true });
          commit("setCreating", false);
          await commit("setPayment", newPayments);
          resolve(newPayments);
        })
        .catch((err) => {
          reject(err);

          if (
            err.response.data.data.lista_mensaje[0].mensaje.includes(
              "no existe"
            )
          ) {
            const formasPago = [{ type: true }];

            commit("setPayment", formasPago);
          }
          commit("setCreating", false);
        });
    });
  },

  mpayAction({ commit }, params) {
    const url = params?.path;
    const data = params?.body;
    const mutation = params?.commit;
    return new Promise((resolve, reject) => {
      axios
        .post({ url, data })
        .then((response) => {
          const res = response.data.data;
          if (mutation) {
            commit(mutation, res.result);
          }
          resolve(res);
        })
        .catch((error) => {
          const errorData = error.response.data;
          let message;
          try {
            message = `${mpayError}: ${errorData.data.lista_mensaje[0].mensaje}`;
          } catch {
            message = `${mpayError}.`;
          }
          reject(message);
        });
    });
  },

  sendMPay({ dispatch }, payload) {
    // Payload => Header:Nro de propuesta, Body:Medio de pago Item
    const path = `/medio-pagos/mpay/${payload.header}`;
    return dispatch("mpayAction", { ...payload, path, commit: "setMPayState" });
  },

  sendMPay2({ dispatch }, payload) {
    // Payload => Header:Nro de propuesta, Body:Medio de pago Item
    const path = `/medio-pagos/mpay2/${payload.header}`;
    return dispatch("mpayAction", { ...payload, path, commit: "setMPayState" });
  },

  getMPay2Estado({ dispatch, state }) {
    const path = `/medio-pagos/mpay2/${state.proposal.numero_propuesta}/estado`;
    return dispatch("mpayAction", { path });
  },

  cancelMPay2({ state, dispatch }) {
    const path = `/medio-pagos/mpay2/${state.proposal.numero_propuesta}/dar-de-baja`;
    const body = {
      motivo: "Dar de baja",
    };
    return dispatch("mpayAction", { path, body });
  },

  getBenefictsProposal({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios({
        url: `propuestas/${data.numero_propuesta}/beneficiarios`,
        method: "GET",
      })
        .then((res) => {
          commit("setBeneficts", res.data.data.beneficiarios);
          resolve(res.data.data.beneficiarios);
        })
        .catch((err) => {
          commit("setBeneficts", []);

          reject(err);
        });
    });
  },
  updateInsured({ commit, dispatch }, data) {
    commit("setCreating", true);
    return new Promise((resolve, reject) => {
      axios({
        url: `propuestas/${data.numero_propuesta}/beneficiarios`,
        method: "PUT",
        data: data.benefict_data,
      })
        .then((res) => {
          commit("setError", false);
          dispatch("getBenefictsProposal", {
            numero_propuesta: data.numero_propuesta,
          });
          commit("setCreating", false);
          resolve(res.data.data);
        })
        .catch((error) => {
          commit("setCreating", false);
          commit("setError", true);
          reject(error);
        });
    });
  },

  newClientPayment(context, payload) {
    const { id, ...data } = payload;
    const url = `${URL_BFF}/propuestas/${id}/agregar-medio-pago`;
    return new Promise((resolve, reject) => {
      axios({ method: "POST", url, data })
        .then((response) => {
          resolve({
            result: response.data?.data,
            info: "Se ha registrado el medio de pago correctamente!",
          });
        })
        .catch((error) => {
          reject(
            new Error(
              error.response?.data?.data?.lista_mensaje?.[0]?.mensaje ||
                "Ha ocurrido un error al intentar registrar un medio de pago."
            )
          );
        });
    });
  },

  updateBenefict({ commit }, data) {
    commit("setCreating", true);
    return new Promise((resolve, reject) => {
      axios({
        url: `propuestas/${data.numero_propuesta}/beneficiarios`,
        method: "PUT",
        data: data.benefict_data,
      })
        .then((res) => {
          resolve(res.data.data);
          commit("setCreating", false);
          commit("setError", false);
        })
        .catch((error) => {
          reject(error);
          commit("setCreating", false);
          commit("setError", true);
        });
    });
  },
  updateGood({ commit }, data) {
    commit("setCreating", true);
    return new Promise((resolve, reject) => {
      axios({
        url: `propuestas/${data.numero_propuesta}/bien`,
        method: "PUT",
        data: data.good_data,
      })
        .then((res) => {
          commit("setCreating", false);
          commit("setError", false);
          resolve(res.data.data);
        })
        .catch((error) => {
          commit("setCreating", false);
          commit("setError", true);
          reject(error);
        });
    });
  },
  updateRequirements({}, payload) {
    const formData = payload.form,
      path = `/propuestas/${payload.numero_propuesta}/seccion/requisitos/${
        formData.auto_es_nuevo ? "nuevo" : "usado"
      }`;
    return new Promise((resolve, reject) => {
      axios
        .post(path, formData)
        .then((response) => {
          resolve(response.data);
        })
        .catch(() =>
          reject(
            new Error(
              "No se ha logrado actualizar la propuesta con los requisitos."
            )
          )
        );
    });
  },
  createPayer({ commit }, data) {
    commit("setCreating", true);
    return new Promise((resolve, reject) => {
      axios({
        url: `propuestas/${data.numero_propuesta}/pagador`,
        method: "POST",
        data: data.payer_data,
      })
        .then((res) => {
          commit("setCreating", false);
          commit("setError", false);
          resolve(res.data.data);
        })
        .catch((error) => {
          commit("setCreating", false);
          commit("setError", true);
          reject(error);
        });
    });
  },

  saveProposalData({ commit }, data) {
    commit("setProposalData", data);
  },

  updateProposalEmail({ commit }, payload) {
    // Actualizar el EMAIL del cliente en una propuesta
    // Payload => id: Numero de propuesta | email: correo del cliente
    const path = `/propuestas/${payload?.id ?? ""}/modificar-correo`;
    return new Promise((resolve, reject) => {
      axios
        .put(path, { correo: payload?.email ?? "" })
        .then((response) => {
          const result = response?.data?.data;
          commit("setProposalEmail", payload.email);
          resolve({
            message: "Se ha actualizado el correo electronico correctamente!",
            data: result?.documentos ?? [],
            email: result?.correo ?? "",
          });
        })
        .catch(() => {
          reject(
            new Error("No se ha logrado actualizar el correo electronico.")
          );
        });
    });
  },
  findProposalDocuments({ commit }, payload) {
    //Payload => numero de propuesta
    const path = `/documentos/${payload}/getAll`;
    return new Promise((resolve, reject) => {
      axios
        .get(path)
        .then(async (response) => {
          const result = response.data?.data?.result;
          let success = true;
          if (result) {
            const regularDocument = result?.documentosListos ?? [];
            regularDocument.forEach((e) => (e.loading = false));
            commit("SetRegularDocuments", regularDocument);

            const pendingDocument = result?.documentosPendientes ?? [];
            pendingDocument.forEach((e) => {
              e.loading = false;
              e.loading_upload = false;
            });
            commit("SetPendingDocuments", pendingDocument);

            commit("SetToppings", result?.coberturas_exclusiones ?? []);

            const proposal = result?.propuesta ?? {};
            proposal[defaultLogoPropName] = proposal[companyLogoUrl];

            // Establecer propuesta seleccionada
            commit("setProposal", proposal ?? {});
          } else {
            success = false;
          }
          resolve({ data: result, success });
        })
        .catch(() => {
          reject(
            new Error(
              "Ha ocurrido un error al intentar recuperar los Documentos y Coberturas."
            )
          );
        });
    });
  },
  // OBTENER DATOS DEL CARRITO (Propuesta estado Seleccionada)
  getResumeCart({ commit }, payload) {
    const url = `carrito/${payload}`;
    return new Promise((resolve, reject) => {
      axios({
        url,
        method: "GET",
      })
        .then((res) => {
          const result = res.data.data;
          // Verificar que haya una propuesta activa
          if (result?.Propuesta?.length <= 0) {
            throw Error(
              "No se ha encontrado una propuesta para esta cotización."
            );
          }
          // Condiciones de las ofertas
          const Ofertas = result?.oferta_seleccionada;
          for (const i in Ofertas) {
            Ofertas[i][defaultLogoPropName] = Ofertas[i][companyLogoUrl];
          }

          // Establecer la tarifa seleccionada
          if (result?.oferta_seleccionada?.length > 0) {
            commit("setTarifa", result.oferta_seleccionada[0]);
          }
          // Establecer datos del carrito
          const responseData = {
            propuesta: result?.Propuesta[0],
            complementarios: result?.complementarios ?? [],
            canal: result?.canal ?? null,
            adicionales: result?.adicionales_seleccionados ?? [],
            cantPlanes: result?.numero_planes ?? 1,
            ofertas: result?.oferta_seleccionada ?? [],
          };
          commit("setShoppingCart", responseData);
          resolve(responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  // OBTENER DATOS DE LA PROPUESTA (Propuesta estado Falta informacion)
  getProposal({ commit }, payload) {
    const labelCommit = "setProposalGood";
    return new Promise((resolve, reject) => {
      axios({
        url: `propuestas/${payload}`,
        method: "GET",
      })
        .then((res) => {
          const result = res.data.data.Propuesta;
          // Guardar Propuesta
          commit("setProposal", result);
          // Guardar la tarifa seleccionada (en el caso que la traiga)
          if (result?.tarifa_seleccionada) {
            const tarifa = result.tarifa_seleccionada;
            tarifa[defaultLogoPropName] = tarifa[companyLogoUrl];
            commit("setTarifa", tarifa);
          }
          // Establecer el paso de retoma
          commit("changeProposalStep", result?.step ?? 1);

          if (result.datos_bien) {
            const datosBien = [];
            datosBien.push(result.datos_bien);
            commit(labelCommit, { datos_bien: datosBien });
          } else {
            commit(labelCommit, { datos_bien: [] });
          }

          resolve(result);
        })
        .catch((e) => {
          const error =
            typeof e === "string"
              ? e
              : e.response.data.data?.lista_mensaje?.[0]?.mensaje ??
                "Ha ocurrido un error al intentar recuperar la propuesta.";
          commit(labelCommit, { datos_bien: [] });
          reject(new Error(error));
        });
    });
  },

  REQUEST_INSURABLE_INTEREST({ commit }) {
    const path = `${URL_API_NEGOCIO}/interes-asegurables`;
    return new Promise((resolve, reject) => {
      axios
        .get(path)
        .then((response) => {
          const result = response.data.data.result;
          commit("setInsurableInterest", result);
          resolve("ok");
        })
        .catch(() =>
          reject(
            new Error(
              "Ha ocurrido un error al intentar recuperar el listado de interes asegurable."
            )
          )
        );
    });
  },
  REQUEST_DOCUMENT_TYPE({ commit }, payload = {}) {
    const path = `${URL_API_NEGOCIO}/tipo-documentos`,
      params = {
        getInactivos: payload.inactivos ? payload.inactivos : false,
      };
    return new Promise((resolve, reject) => {
      axios({
        url: path,
        method: "GET",
        params: params,
      })
        .then((response) => {
          const result = response.data.data.result;
          commit("setTypeOfDocuments", result);
          resolve(result);
        })
        .catch(() =>
          reject(
            new Error(
              "No se ha logrado recuperar los tipos de documentos de venta"
            )
          )
        );
    });
  },

  getColors({ commit }) {
    const path = `${URL_API_NEGOCIO}/colores-vehiculos`;
    return new Promise((resolve, reject) => {
      axios({
        url: path,
        method: "GET",
      })
        .then((res) => {
          const result = res.data.data.result;
          const sResult = result.sort((a, b) => {
            const fa = a.descripcion.toLowerCase(),
              fb = b.descripcion.toLowerCase();
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          });
          commit("setColors", sResult);
          resolve(sResult);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getDataProposalStep({ commit }, payload) {
    // Payload => id: nro de propuesta, step: nro de paso del formulario
    const path = `/propuestas/${payload.id}/step`,
      formData = { step: payload.step };
    return new Promise((resolve, reject) => {
      axios
        .post(path, formData)
        .then((res) => {
          const result = res.data.data.propuesta;
          commit("setDataProposalStep", result);
          resolve(result);
        })
        .catch((err) => reject(err));
    });
  },

  setBeneficiarios({ commit }, payload) {
    const path = `propuestas/${payload.id}/beneficiarios`;
    const formData = payload.form;
    return new Promise((resolve, reject) => {
      axios
        .put(path, formData)
        .then((response) => {
          const result = response.data.data;
          // Actualizar el asegurado de la propuesta
          if (result?.beneficiarios?.length > 0) {
            commit("setInsured", result.beneficiarios[0]);
          }
          resolve({
            result,
            success: true,
          });
        })
        .catch((e) => {
          const info =
            e.response.data?.data?.lista_mensaje?.[0]?.mensaje ??
            "Ha ocurrido un error al intentar guardar los beneficiarios";
          reject({ info, success: false });
        });
    });
  },

  updateGoodies({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios({
        url: `propuestas/${payload.id}/bien`,
        method: "PUT",
        data: payload.data,
      })
        .then((response) => {
          const result =
            response.data.data?.datos_bien ??
            response.data.data?.propuesta?.datos_bien ??
            {};
          commit("setGoodies", result);
          resolve("Se ha actualizado los datos del bien correctamente.");
        })
        .catch((e) => {
          const error = e.response.data;
          let errorText =
            "Ha ocurrido un error al intentar guardar la información del bien asegurado.";
          if (error?.data?.lista_mensaje?.length > 0) {
            errorText = error.data.lista_mensaje
              .map((a) => a.mensaje)
              .join(", ");
          }
          reject(new Error(errorText));
        });
    });
  },

  refreshCompanies(payload) {
    const path = `${URL_API_NEGOCIO}/inspecciones/canal/${payload.state.proposal.canal_logueado}`;
    return new Promise((resolve, reject) => {
      axios
        .get(path)
        .then((res) => {
          const result = res.data.data.inspecciones;
          resolve(result);
        })
        .catch((err) => reject(err));
    });
  },
};

export default actions;
