import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

export default {
  namespaced: true,
  state: {
    comunas: [],
    regiones: [],
    provincias: [],
    tipo_documentos: [],
    tipo_personas: null,
    loadingData: true,
  },
  actions,
  mutations,
  getters,
};
