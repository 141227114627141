<template>
  <v-dialog
    :value="value"
    width="529px"
    attach="#main-container"
    @click:ouside="close">
    <v-card>
      <div class="py-3 card-content">
        <v-card-title primary-title>
          Carrito de compra
          <v-spacer></v-spacer>
          <v-btn icon @click="close">
            <Icon name="close" size="25px" />
          </v-btn>
        </v-card-title>
        <v-row no-gutters>
          <v-col
            cols="12"
            class="pa-3"
            v-for="(item, index) in planData"
            :key="index">
            <v-card elevation="0" class="bg-primary">
              <cardPlan :SeguroDetails="item" />
              <div class="d-flex w-100 justify-end">
                <v-btn
                  text
                  small
                  color="primary"
                  class="text-none"
                  @click="VerMas(item)">
                  <v-icon left>mdi-eye</v-icon>
                  Ver más
                </v-btn>
                <v-btn
                  text
                  small
                  :loading="deleteloader"
                  :disabled="index === 0"
                  class="text-none"
                  ><v-icon left>mdi-trash-can</v-icon>
                  Eliminar
                </v-btn>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <ResumeBuyCard
              :planData="planData"
              :total_pesos="total_pesos"
              :total_uf="total_uf" />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12 py-4" class="text-center">
            <v-btn
              color="primary"
              width="180"
              rounded
              class="text-none"
              @click="close"
              >Entendido</v-btn
            >
          </v-col>
        </v-row>
      </div>
    </v-card>
    <Modal
      :open="modaldetails"
      :selected="VerMasItem"
      @close="closeBtn"
      :showQuoteActions="false" />
  </v-dialog>
</template>

<script>
import Modal from "@/components/Modal/Modal-Detail";
import cardPlan from "@/components/Cards/Plan-card";

import Icon from "@/components/Icon/Icon.vue";
import ResumeBuyCard from "@/components/Cards/ShoppingCart/ResumeNavbarCart.vue";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    planData: {
      type: Array,
      default: () => [],
    },
    total_uf: {
      type: Number,
      default: 0,
    },
    total_pesos: {
      type: Number,
      default: 0,
    },
    contador: {
      type: Number,
      default: 0,
    },
    cuotas: {
      type: Number,
      default: 0,
    },
  },
  components: {
    cardPlan,
    ResumeBuyCard,
    Modal,
    Icon,
  },
  data() {
    return {
      dataProduct: [],
      modaldetails: false,
      VerMasItem: {},
      deleteloader: null,
    };
  },
  methods: {
    close() {
      this.$emit("close", true);
    },
    closeBtn() {
      this.modaldetails = false;
    },
    VerMas(value) {
      this.VerMasItem = value;
      this.modaldetails = true;
    },
  },
};
</script>

<style scoped>
.card {
  height: 699px;
  width: 805px;
  left: 0px;
  top: 0px;
  border-radius: 10px;
}
.details-card {
  height: 228px;
  width: 734px;
  top: 0px;
  border-radius: 10px;
  background: #f2f2f2;
}
.card-title {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}
.list {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.pay {
  height: 46px;
  width: 700px;
  left: 0px;
  top: 0px;
  border-radius: 5px;
}
.paytxt {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.finalPrice {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
}
.prices {
  position: absolute;
  right: 0;
}
.btn {
  height: 45px;
  width: 473px;
  border-radius: 5px;
  padding: 14px, 45px, 14px, 45px;
}
.scrollable {
  max-height: 400px;
  overflow-y: auto;
}
</style>
