import axios from "axios";

const actions = {
  getLocations({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios({
        url: `territorios/pais/codigo/${payload}`,
        method: "GET",
      })
        .then((res) => {
          const provincias = [];
          const comunas = [];
          const regiones = [];
          const data = res.data.data.regiones;
          data.forEach((region) => {
            regiones.push({
              nombre: region.nombre,
              codigo: region.codigo,
              nivel: region.nivel,
              _id: region._id,
              provincias: region.provincias,
            });

            region.provincias.forEach((provincia) => {
              provincias.push({
                nombre: provincia.nombre,
                codigo: provincia.codigo,
                nivel: provincia.nivel,
                _id: provincia._id,
                comunas: provincia.comunas,
              });

              provincia.comunas.forEach((comuna) => {
                comunas.push({
                  nombre: comuna.nombre,
                  codigo: comuna.codigo,
                  nivel: comuna.nivel,
                  _id: comuna._id,
                });
              });
            });
          });

          sessionStorage.setItem("regiones", JSON.stringify(regiones));
          sessionStorage.setItem("comunas", JSON.stringify(comunas));
          sessionStorage.setItem("provincias", JSON.stringify(provincias));
          commit("setComunas", comunas);
          commit("setRegiones", regiones);
          commit("setProvincias", provincias);
          resolve(comunas);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getTiposDocumentos({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios({
        url: `documentos/pais/codigo/${payload}`,
        method: "GET",
      })
        .then((res) => {
          commit("setTipoDocumentos", res.data.data.data);
          sessionStorage.setItem(
            "tipo_documentos",
            JSON.stringify(res.data.data.data)
          );
          resolve(res.data.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getTiposPersonas({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios({
        url: `personas/pais/codigo/${payload}`,
        method: "GET",
      })
        .then((res) => {
          commit("setTipoDocumentos", res.data.data.data);
          sessionStorage.setItem(
            "tipo_personas",
            JSON.stringify(res.data.data.data)
          );
          resolve(res.data.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
export default actions;
