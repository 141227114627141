<template>
  <div class="mainContainer">
    <div class="d-flex justify-center align-center flex-column">
      <v-img src="../assets/LoginImages/notAuthorized.png" />
      <div class="alert d-flex align-center">
        <Icon name="error" size="25px" color="#CA1414" />
        <span class="ml-3">
          Lo sentimos pero <b>no está autorizado</b> para ver este módulo o su
          sesión ha caducado
        </span>
      </div>

      <div class="my-8">
        <p class="text-center">Por favor, inicie sesión en Msuite</p>
        <v-btn
          @click="handleClick"
          width="367"
          color="primary"
          rounded
          class="text-none"
          >Ir a Msuite</v-btn
        >
      </div>
    </div>
  </div>
</template>
<script>
import Icon from "@/components/Icon/Icon.vue";

export default {
  name: "ListadoAprobacionPersonas",
  components: {
    Icon,
  },
  data() {
    return {};
  },
  methods: {
    handleClick() {
      window.open(process.env.VUE_APP_MSUIT_URL, "_blank");
    },
  },
  mounted() {},
  computed: {},
};
</script>

<style scoped>
.mainContainer {
  height: 100%;
  width: 100%;
  background-color: #f4f7fc;
  display: flex;
  justify-content: center;
  align-items: center;
}

.v-btn__content {
  letter-spacing: normal !important;
}

.alert {
  border-radius: 4px;
  border-left: 6px solid #ca1414;
  background: rgba(202, 20, 20, 0.1);
  padding: 16px;
}
</style>
