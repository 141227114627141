<template>
  <v-card class="light px-4 py-3 invisible" elevation="0">
    <div class="d-flex justify-space-between align-center border">
      <span class="h4 semiBold">Resumen de compra</span>
      <span class="h6">A pagar</span>
    </div>
    <div
      v-for="(item, i) in planData"
      :key="i"
      class="d-flex justify-space-between align-center my-4">
      <span class="h5"> {{ item.plan_descripcion }}</span>
      <span class="h5">
        {{ item.moneda_local.simbolo }}
        {{ item.moneda_local.precio.toLocaleString("es-CL") }} |
        {{ item.moneda_seguro.simbolo }}
        {{ item.moneda_seguro.precio.toLocaleString("es-CL") }}</span
      >
    </div>
    <div class="d-flex justify-space-between align-center mt-8">
      <span class="h4 semiBold">A pagar</span>
      <span class="h4 semiBold">
        {{ planData[0].moneda_local.simbolo }}
        {{ totalPesos.toLocaleString("es-CL") }} |
        <span class="primary--text semiBold">
          {{ planData[0].moneda_seguro.simbolo }}
          {{ totalUF.toLocaleString("es-CL") }}</span
        ></span
      >
    </div>
  </v-card>
</template>
<script>
export default {
  props: ["planData", "total_pesos", "total_uf"],
  computed: {
    totalPesos() {
      var contador = 0;
      this.planData.map((it) => {
        contador += it.moneda_local.precio;
      });
      return contador;
    },
    totalUF() {
      var contador = 0;
      this.planData.map((it) => {
        contador += it.moneda_seguro.precio;
      });
      return contador;
    },
  },
};
</script>
<style scoped>
.v-card > *:last-child:not(.v-btn):not(.v-chip) {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.v-list {
  display: block;
  padding: 0;
  position: static;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  will-change: box-shadow;
}

.border {
  border-bottom: 1px #ccc solid;
}
</style>
