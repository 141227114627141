<template name="IconComponent">
  <span class="material-symbols-rounded"> {{ name }} </span>
</template>
<script>
export default {
  name: "IconComponent",
  props: {
    name: String,
    size: {
      type: String,
      default: "15px",
    },
    color: {
      type: String,
      default: "white",
    },
  },
  computed: {},
  methods: {},
};
</script>
<style scoped>
.material-symbols-rounded {
  color: v-bind("color") !important;
  font-size: v-bind("size");
}
</style>
