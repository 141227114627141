import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const messages = {
  es: {
    saludo: "Hola",
    automotriz: "Automotriz",
    companies: "Compañias",
    goBackToClientDetails: "Volver al detalle del cliente",
    primaCardPlanModal: "Prima Mensual",
  },
  "es-CL": {
    saludo: "Hola",

    country: "Chileno",
    // patente: "¿El auto cuenta con patente o factura?",
    companies: "Compañias",
    goBackToClientDetails: "Volver al detalle del cliente",
    primaCardPlanModal: "Prima Mensual",
    deptoCasa: "Departamento/casa",
    commune: "Comuna",
    patenteoFactura: "¿El auto cuenta con patente o factura?",
    patente: "Patente",
  },

  "es-PE": {
    country: "Peruano",
    automotriz: "Vehicular",
    // patente: "¿El vehículo cuenta con placa o está en trámite?",
    companies: "Aseguradoras",
    goBackToClientDetails: "Volver a cotizar",
    primaCardPlanModal: "Prima Anual",
    patenteoFactura: "¿El vehículo cuenta con placa o está en trámite?",
    deptoCasa: "Casa/Referencia",
    patente: "Placa",
    commune: "Departamento",
  },
};

const i18n = new VueI18n({
  locale: "es-CL",
  falllbackLocale: "es" | "es-CL",
  messages,
});

export default i18n;
