const getters = {
  comunas: (state) => {
    return state.comunas;
  },
  regiones: (state) => {
    return state.regiones;
  },
  isLoading: (state) => {
    return state.loadingData;
  },
  provincias: (state) => {
    return state.provincias;
  },
  tipoDocumentos: (state) => {
    return (
      JSON.parse(sessionStorage.getItem("tipo_documentos")) ||
      state.tipo_documentos
    );
  },
  tipoPersonas: (state) => {
    return (
      JSON.parse(sessionStorage.getItem("tipo_personas")) || state.tipo_personas
    );
  },
};

export default getters;
