<template>
  <v-container fluid>
    <v-row>
      <v-col cols="3" class="ma-0 pa-0">
        <div class="d-grid">
          <div class="justify-self-center pa-1">
            <v-fade-transition>
              <v-img
                :src="SeguroDetails?.[logoName] ?? ''"
                @error="handleLogoError($event, SeguroDetails)"
                width="120"
                max-height="95"
                contain>
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center">
                    <v-progress-circular
                      indeterminate
                      color="primary"></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-fade-transition>
          </div>
          <div
            class="badge primary-bg text-center w-70 mt-3 mb-2 vigency-container">
            <span class="text-white">
              {{
                SeguroDetails?.objeto_vigencia?.vigencia_descripcion ??
                SeguroDetails?.vigencia?.vigencia_descripcion ??
                ""
              }}
            </span>
          </div>
        </div>
      </v-col>

      <v-col cols="9" class="py-0">
        <div class="mb-1">
          <span class="bold font-20">
            {{ SeguroDetails?.nombre_producto ?? "--" }}
          </span>
        </div>
        <div>
          <span class="semiBold font-20" v-if="Cotizacion">
            {{ Cotizacion?.marca_descripcion ?? "" }}
            {{ Cotizacion?.modelo_descripcion ?? "" }}
            {{ Cotizacion?.anio ?? "" }}
          </span>
        </div>
        <span class="font-14">
          Deducible
          <span class="h5 bold primary--text ml-3 font-18">{{
            SeguroDetails?.deducible ?? 0
          }}</span> </span
        ><br />

        <span class="font-14">
          {{ primaCardPlanModal }}

          <h6 class="pl-3" v-if="SeguroDetails?.prima_mensual">
            {{ SeguroDetails.moneda_local.simbolo }}
            {{
              SeguroDetails?.moneda_local?.precio.toLocaleString("es-CL") ||
              SeguroDetails?.prima_mensual.toLocaleString("es-CL") ||
              ""
            }}
          </h6>
          <span class="px-3 bold font-18" v-else>
            {{ SeguroDetails.moneda_local.simbolo }}
            {{ SeguroDetails.moneda_local.precio.toLocaleString("es-CL") }}
            <!-- {{ (SeguroDetails?.moneda_local?.precio ?? "0") | moneyFormat }} -->
          </span>
          <span class="grey--text font-18">|</span>
          <span class="px-3 bold primary--text font-18">
            {{ SeguroDetails.moneda_seguro.simbolo }}
            {{ SeguroDetails.moneda_seguro.precio }}
            <!-- {{
              (SeguroDetails?.moneda_seguro?.precio ||
                SeguroDetails?.prima_mensual_seguro ||
                "") | UFPrice
            }} -->
          </span>
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import LogoRequestMixin from "@/Mixins/LogoRequestMixin.js";
export default {
  mixins: [LogoRequestMixin],
  name: "SeguroDetalle",
  props: ["SeguroDetails", "Cotizacion"],
  computed: {
    primaCardPlanModal() {
      return this.$i18n.messages[this.$i18n.locale].primaCardPlanModal;
    },
  },
};
</script>

<style scoped>
@import "../../assets/CSS/ServiceStyle.css";

.d-grid {
  display: grid;
}
.justify-self-center {
  justify-self: center;
}
.badge {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.vigency-container {
  height: 37px;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 129px;
}
</style>
