const mutations = {
  changeStatusQuotation(state, status) {
    state.creatingQuotation = status;
  },
  setErrorRetake(state, status) {
    state.errorRetake = status;
  },
  changeProposalStep(state, payload) {
    state.step = payload;
  },

  setRetaking(state, status) {
    state.retaking = status;
  },

  setOffersQuotation(state, offers) {
    state.offersQuotation = offers;
  },

  setQuotationState(state, status) {
    state.quotationState = status;
  },

  setCities(state, cities) {
    state.cities = cities;
    sessionStorage.setItem("cities_one", JSON.stringify(cities));
  },

  setCitiesTwo(state, cities) {
    state.cities = cities;
    sessionStorage.setItem("cities_two", JSON.stringify(cities));
  },

  setPayment(state, methods) {
    const mpayElement = methods.find(
      (e) => e.codigo === "MPAY" && e.codigo_estado === "PENDIENTE"
    );
    if (mpayElement) {
      state.blocked_by_payment_method = true;
    } else {
      state.blocked_by_payment_method = false;
    }

    state.payment_methods = [];
    state.payment_methods = methods.map((element) => element);
  },

  setPaymentStatus(state, status) {
    state.payment_methods.forEach((element) => {
      if (element.codigo === "MPAY") {
        element.codigo_estado = status;
        if (status === "ACEPTADO" || status === "RECHAZADO") {
          state.blocked_by_payment_method = false;
        }
      }
    });
  },

  setMPayState(state, payload) {
    sessionStorage.setItem("mpay", JSON.stringify(payload));
    state.mpay = payload;
  },

  setBuyCart(state) {
    state.buy_cart = !state.buy_cart;

    sessionStorage.setItem("showBuyCart", state.buy_cart);
  },

  setShoppingCart(state, resume) {
    sessionStorage.setItem("buy_cart_resume", JSON.stringify(resume));
    state.buy_cart_resume = resume;
  },

  setTarifa(state, payload) {
    sessionStorage.setItem("tarifa_selected", JSON.stringify(payload));
    state.tarifa = payload;
  },

  setProposalEmail(state, payload) {
    if (state.proposal_data?.cotizacion) {
      state.proposal_data.cotizacion.correo_cliente = payload;
    }
  },

  SetRegularDocuments: (state, payload) => (state.documents.regular = payload),
  SetPendingDocuments: (state, payload) => (state.documents.pending = payload),
  SetToppings: (state, payload) => (state.documents.toppings = payload),

  setCreating(state, status) {
    state.creating = status;
  },
  setError(state, status) {
    state.error_creating = status;
  },

  setBeneficts(state, data) {
    sessionStorage.setItem("beneficts", JSON.stringify(data));
    state.beneficts = data;
  },

  setProposalGood(state, data) {
    state.proposal_good = data;
  },

  // Actualizar los datos del bien asegurado en la propuesta
  setGoodies(state, payload) {
    state.proposal.datos_bien = payload;
  },

  setInsured(state, data) {
    state.proposal.asegurado = data;
  },

  setCompanyLogo(state, payload) {
    state.companyLogo[payload.name] = payload.value;
  },

  setInsurableInterest(state, payload) {
    state.InsurableInterestList = payload;
  },
  setTypeOfDocuments(state, payload) {
    state.documentType = payload;
  },
  setColors(state, payload) {
    state.colors = payload;
  },
  setProposal(state, payload) {
    sessionStorage.setItem("proposal_data", JSON.stringify(payload));
    state.proposal = payload;
  },
  setDataProposalStep(state, payload) {
    state.dataProposalStep = payload;
  },
};

export default mutations;
