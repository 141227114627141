<template>
  <div class="loader-container">
    <div class="container d-flex w-100 justify-center">
      <div class="loader d-flex justify-center align-center">
        <div class="dot dot1"></div>
        <div class="dot dot2"></div>
        <div class="dot dot3"></div>
        <div class="dot dot4"></div>
        <div class="dot dot5"></div>
        <div class="dot dot6"></div>
        <div class="dot dot7"></div>
        <div class="movingContainer">
          <div class="movingDot"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "loading-layout",
  async mounted() {
    const encryptedToken = this.$route?.query?.token?.toString();

    let token = "";
    if (encryptedToken) {
      token = this.$CryptoJS.AES.decrypt(
        encryptedToken,
        process.env.VUE_APP_CRYPTO_KEY.toString()
      ).toString(this.$CryptoJS.enc.Utf8);
    } else {
      this.$router.push({ name: "NotAuthorized" });
    }
    const sessionToken = sessionStorage.getItem("token");
    if (token || sessionToken) {
      this.$store.commit("REFRESH_TOKEN", token || sessionToken);
      const user = await this.$store.dispatch("user");
      if (user) {
        this.$store.commit("setChannels", user.canal);
        await this.$store.dispatch("REQUEST_CHANNEL_INFO", user.canal[0]);
        this.$router.push({ name: "dashboard" });
      }
    }
    var url = window.location.href.split("/");
    this.$store.commit("SET_URL_CONVENIO", url[2]);
  },
};
</script>

<style>
.loader-container {
  z-index: 100;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 16px;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
}
.loader {
  margin-top: 200px;
  width: 250px;
  position: relative;
}

.dot {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: red;
  margin-right: 15px;
}
.dot1 {
  animation: dot-1 2s infinite linear;
  background-color: #4feef7;
}
.dot2 {
  animation: dot-2 2s infinite linear;
  background-color: #4ce0f4;
}
.dot3 {
  animation: dot-3 2s infinite linear;
  background-color: #4dd4ec;
}
.dot4 {
  animation: dot-4 2s infinite linear;
  background-color: #4fcceb;
}
.dot5 {
  animation: dot-5 2s infinite linear;
  background-color: #4db9f0;
}
.dot6 {
  animation: dot-6 2s infinite linear;
  background-color: #4cadf3;
}
.dot7 {
  animation: dot-1 2s infinite 1.1s linear;
  background-color: #4d9ee6;
}
.movingContainer {
  position: absolute;
  left: 0;
  animation: move 2s infinite ease-in-out;
}
.movingDot {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: red;
  animation: expand 2s infinite ease-out;
}
@keyframes expand {
  0% {
    width: 40px;
    background-color: #4feef7;
  }
  13% {
    background-color: #4ce0f4;
  }
  25% {
    width: 70px;
    background-color: #4fcceb;
  }
  38% {
    background-color: #4cadf3;
  }

  50% {
    width: 40px;
    background-color: #4d9ee6;
  }
  63% {
    background-color: #4cadf3;
  }
  75% {
    width: 70px;
    background-color: #4fcceb;
  }
  88% {
    background-color: #4ce0f4;
  }

  100% {
    width: 40px;
    background-color: #4feef7;
  }
}
@keyframes move {
  0% {
    left: -10px;
  }
  25% {
  }
  50% {
    left: 205px;
  }
  75% {
  }
  100% {
    left: -10px;
  }
}
@keyframes dot-1 {
  0% {
    transform: scale(1, 1);
  }
  10% {
    transform: scale(0.8, 0.8);
  }
  15% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(1, 1);
  }
}
@keyframes dot-2 {
  0% {
    transform: scale(1, 1);
  }
  15% {
    transform: scale(1, 1);
  }
  20% {
    transform: scale(0.8, 0.8);
  }
  25% {
    transform: scale(1, 1);
  }

  85% {
    transform: scale(1, 1);
  }
  90% {
    transform: scale(0.8, 0.8);
  }
  95% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(1, 1);
  }
}
@keyframes dot-3 {
  0% {
    transform: scale(1, 1);
  }
  20% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(0.8, 0.8);
  }
  30% {
    transform: scale(1, 1);
  }

  80% {
    transform: scale(1, 1);
  }
  85% {
    transform: scale(0.8, 0.8);
  }
  90% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(1, 1);
  }
}
@keyframes dot-4 {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(0.8, 0.8);
  }
  35% {
    transform: scale(1, 1);
  }

  75% {
    transform: scale(1, 1);
  }
  80% {
    transform: scale(0.8, 0.8);
  }
  85% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(1, 1);
  }
}
@keyframes dot-5 {
  0% {
    transform: scale(1, 1);
  }
  30% {
    transform: scale(1, 1);
  }
  35% {
    transform: scale(0.8, 0.8);
  }
  40% {
    transform: scale(1, 1);
  }

  73% {
    transform: scale(1, 1);
  }
  78% {
    transform: scale(1.4, 1.4);
  }
  83% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(1, 1);
  }
}
@keyframes dot-6 {
  0% {
    transform: scale(1, 1);
  }
  40% {
    transform: scale(1, 1);
  }
  45% {
    transform: scale(1.4, 1.4);
  }
  50% {
    transform: scale(1, 1);
  }

  65% {
    transform: scale(1, 1);
  }
  70% {
    transform: scale(1.4, 1.4);
  }
  75% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(1, 1);
  }
}
</style>
