import Vue from "vue";
import VueRouter from "vue-router";

// Layouts
import Dashboard from "../layouts/Dashboard.vue";
import Loader from "../layouts/Loader.vue";
import ErrorLayout from "../layouts/Error.vue";
import NotAuthorized from "../layouts/NotAuthorized.vue";

// Pages
import DashboardPages from "./dashboard";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Loader,
  },
  {
    path: "/home",
    component: Dashboard,
    children: DashboardPages,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/not-authorized",
    name: "NotAuthorized",
    component: NotAuthorized,
  },
  {
    path: "/error",
    name: "Error",
    component: ErrorLayout,
  },
  {
    path: "*",
    component: ErrorLayout,
    props: () => ({ codigo: 4 }),
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  mode: "history",
});

router.beforeEach((to, from, next) => {
  next();
});

export default router;
