import axios from "axios";

export const actions = {
  validarCotizacion: ({ commit }, payload) => {
    const path = `cotizaciones/verificar`;
    const formData = {
      nuevo: payload.data.nuevo,
      anio: payload.data.anio,
      tipo_documento: payload.data.tipo_documento,
      numero_documento: payload.data.numero_documento,
      patente: payload.data.patente,
      uso_producto: payload.data.uso_producto,
      uso_producto_descripcion: payload.data.uso_producto_descripcion,
      obj_marca: payload.data.obj_marca,
      obj_modelo: payload.data.obj_modelo,
    };
    return new Promise((resolve, reject) => {
      axios({
        url: path,
        method: "POST",
        data: formData,
      })
        .then((response) => {
          const result = response.data.data.cotizacion;
          commit("setQuotValidacionData", result);
          resolve(result);
        })
        .catch((err) => {
          commit("setQuotValidacionData", null);
          reject(err);
        });
    });
  },

  crearCotizacion: ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `cotizaciones`,
        method: "POST",
        data: payload,
      })
        .then((response) => {
          const result = response.data.data;
          commit("setQuotNumber", result.cotizacion?.numero_cotizacion ?? 0);
          resolve(result);
        })
        .catch((e) => {
          const error = e.response.data;
          error.defaultError =
            "Ha ocurrido un error al intentar generar la simulación";
          reject(error);
        });
    });
  },

  GetCotizaciones({ commit }, params = { limite: 10, pagina: 1 }) {
    const path = `/cotizaciones`;
    return new Promise((resolve, reject) => {
      axios({
        url: path,
        method: "GET",
        params: params,
      })
        .then((res) => {
          const allCoti = res.data.data?.cotizaciones ?? [];
          const pagination = res.data.data?.paginacion ?? null;
          allCoti.map((it, index) => {
            it.plazo_retoma = [];
            it.loading = false;

            it.tarifas.ofertas.map((pr) => {
              it.plazo_retoma.push({
                destacado_descripcion: pr.destacado_descripcion,
                plazo_retoma: pr.plazo_retoma,
                plan: pr.plan,
                deducible: pr.deducible,
                compania: pr.compania_descripcion,
                indice: index,
              });
            });
          });
          commit("SetQuots", allCoti);
          resolve({
            result: allCoti,
            pagination,
          });
        })
        .catch(() => {
          reject(
            "Ha ocurrido un error al intentar recuperar las cotizaciones."
          );
        });
    });
  },
};

export default actions;
