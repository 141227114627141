export const actions = {
  guardarFormulario({ commit }, payload) {
    const { formularioId, datosFormulario } = payload;
    Object.keys(datosFormulario).forEach((campo) => {
      commit("actualizarCampo", {
        formularioId,
        campo,
        valor: datosFormulario[campo],
      });
    });
  },
};

export default actions;
