<template>
  <v-navigation-drawer app width="135" dark>
    <!-- DISTRIBUCION DEL MENU LATERAL -->
    <template v-slot:prepend>
      <div class="px-2 py-6 d-flex justify-center">
        <v-img
          contain
          :src="mainLogo"
          max-height="60"
          max-width="100"
          position="center"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify-start>
              <v-progress-circular
                indeterminate
                color="grey"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </div>
    </template>

    <v-list nav style="padding: 0">
      <template v-for="item in items">
        <v-list-item
          v-if="someCondition(item)"
          :key="item.title"
          link
          style="padding: 0 10px"
          :to="{ name: item.path }"
          dense
        >
          <v-list-item-icon>
            <span class="material-symbols-rounded"> {{ item.icon }} </span>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              <span class="p">
                {{ item.name }}
              </span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>

    <template v-slot:append>
      <v-list dense class="mb-10">
        <v-list-item v-if="showProfileButton" link :to="{ name: 'Profile' }">
          <v-list-item-icon style="margin-right: 10px">
            <span class="material-symbols-rounded"> person </span>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              <span class="p">Perfil</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link @click="support()">
          <v-list-item-icon style="margin-right: 10px" :loading="loadingstatus">
            <span class="material-symbols-rounded"> help </span>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> <span class="p">Ayuda</span></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link @click="logout()">
          <v-list-item-icon style="margin-right: 10px" :loading="loadingstatus">
            <span class="material-symbols-rounded"> logout </span>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> <span class="p">Salir</span></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <div class="d-flex justify-center">
        <span class="version semiBold">versión {{ version }}</span>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { sidebarPages } from "@/store/resources/pages.js";
import { mapGetters } from "vuex";

export default {
  name: "SidebarMenu",
  data() {
    return {
      DropDownItems: false,
      packageVersion: process.env.PACKAGE_VERSION || "0",
    };
  },
  methods: {
    changeDropDownStatus() {
      this.DropDownItems = !this.DropDownItems;
    },
    logout() {
      this.$store.dispatch("logout");
    },
    someCondition(item) {
      const filter =
        this.userChannelInfo?.personalizacion_corporativa?.acceso_pantallas;

      const userFilter = this.user.roles;

      let condition = true;
      switch (item.id) {
        case 1:
          condition =
            !!filter?.find(
              (v) => v.seccion === "BARRA_MENU" && v.codigo === "BOTON_INICIO"
            )?.activo ?? true;
          break;
        case 2:
          condition =
            !!filter?.find(
              (v) =>
                v.seccion === "BARRA_MENU" && v.codigo === "BOTON_COTIZACIONES"
            )?.activo ?? true;
          break;
        case 3:
          condition =
            !!filter?.find(
              (v) =>
                v.seccion === "BARRA_MENU" && v.codigo === "BOTON_DOCUMENTOS"
            )?.activo ?? true;
          break;
        case 4:
          // == Condicion mediante personalizacion corporativa ==
          // condition =
          //   !!filter?.find(
          //     (v) =>
          //       v.seccion === "BARRA_MENU" && v.codigo === "BOTON_DESCUENTOS"
          //   )?.activo ?? true;
          // == Condicion mediante roles de usuario ==
          condition = !!userFilter?.find((v) => v === "descuentos") ?? false;
          break;
        case 5:
          // == Condicion mediante personalizacion corporativa ==
          // condition =
          //   !!filter?.find(
          //     (v) => v.seccion === "BARRA_MENU" && v.codigo === "BOTON_PERSONAS"
          //   )?.activo ?? true;
          // == Condicion mediante roles de usuario ==
          condition = !!userFilter?.find((v) => v === "personas") ?? false;
          break;
        case 6:
          condition = this.advanceInfo.usuario_genera_informe_simulaciones;
          break;

        default:
          condition = true;
          break;
      }
      return condition;
    },
    support() {
      window.open(
        "https://forms.zohopublic.com/mesoschile/form/SoporteSistemas/formperma/pUMVVrHDN8ybFlv6EUhLzt8i7_j84_fXYNZKa3BTMIM",
        "_blank"
      );
    },
  },
  computed: {
    ...mapGetters("Users", ["getMainLogo"]),

    mainLogo() {
      return this.getMainLogo;
    },
    loadingstatus() {
      return this.$store.getters.loadingstatus;
    },
    user() {
      return this.$store.getters.user;
    },
    items() {
      return sidebarPages;
    },
    userChannelInfo() {
      return this.$store.getters.getGeneralInfo;
    },
    version() {
      return this.$store.state.version;
    },

    showProfileButton() {
      return (
        !!this.userChannelInfo?.personalizacion_corporativa?.acceso_pantallas?.find(
          (v) => v.seccion === "BARRA_MENU" && v.codigo === "BOTON_PERFIL"
        )?.activo ?? true
      );
    },

    advanceInfo() {
      return this.$store.getters.getAdvanceInfo || {};
    },
  },
};
</script>

<style scoped>
@import "../../assets/CSS/ServiceStyle.css";
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 5px;
}
.version {
  font-size: 12px;
  color: #003c66;
}
</style>
