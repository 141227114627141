<template>
  <div class="text-center">
    <v-dialog persistent :value="open" max-width="950" attach="#main-container">
      <v-card
        class="overflow-y-hidden overflow-x-hidden"
        :loading="loadingpropuesta || loadingquot || loadShare">
        <div class="d-flex justify-space-between pt-2 px-6">
          <span class="font-20 bold">Detalle del producto</span>
          <v-btn icon :disabled="loadingpropuesta" @click="$emit('close')">
            <v-icon class="global-pointer">mdi-close</v-icon>
          </v-btn>
        </div>
        <div class="d-flex">
          <cardPlan :Cotizacion="selected" :SeguroDetails="selected" />
          <div class="d-flex justify-end flex-grow-1 w-30 align-end pr-8">
            <v-btn
              color="primary"
              :disabled="loadingpropuesta || loadShare"
              @click="sendQuotation(selected)"
              text
              class="text-none"
              ><v-icon left>mdi-share-variant-outline</v-icon>
              <span class="font-16"> Compartir cotización </span>
            </v-btn>
          </div>
        </div>

        <div class="d-flex flex-column justify-center mt-4">
          <div class="d-flex"></div>
          <div v-if="descuentosolicitado">
            <h3>Solicitud descuento enviada</h3>
          </div>

          <!-- <cardPlan :SeguroName="Seguro" :SeguroDetails="Infor" /> -->
        </div>

        <div
          class="w-100 main-background-color py-4 px-5 d-flex justify-space-between">
          <v-row>
            <v-col>
              <v-card flat class="bordered" height="350">
                <div class="card-content overflow-y-auto">
                  <v-card-title class="cardTitle">
                    <h4 class="cardTitle">Coberturas</h4>
                  </v-card-title>

                  <v-card-text>
                    <div v-for="(item, i) in coberturas" :key="i" class="my-4">
                      <div class="d-flex align-center">
                        <div>
                          <v-icon class="primary--text shield-icon"
                            >mdi-shield-check-outline</v-icon
                          >
                        </div>
                        <div class="ml-4">
                          <span class="bold">{{ item.titulo }}</span
                          ><br />
                          <span>{{ item.descripcion }}</span>
                        </div>
                      </div>
                    </div>
                  </v-card-text>
                </div>
              </v-card>
            </v-col>
            <v-col>
              <v-card flat class="bordered" height="350">
                <div class="card-content overflow-y-auto">
                  <v-card-title class="cardTitle">
                    <h4>Exclusiones</h4>
                  </v-card-title>
                  <v-card-text>
                    <div v-if="exclusiones.length > 0">
                      <div
                        v-for="(item, i) in exclusiones"
                        :key="i"
                        class="my-2">
                        <div class="d-flex align-center">
                          <div>
                            <v-icon class="primary--text"
                              >mdi-shield-off-outline</v-icon
                            >
                          </div>
                          <div class="ml-4">
                            <span class="bold">{{ item.titulo }}</span
                            ><br />
                            <span>{{ item.descripcion }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else>Este producto no tiene exclusiones</div>
                  </v-card-text>
                </div>
              </v-card>
            </v-col>
            <v-col cols="12">
              <div class="float-right mb-3">
                <v-btn
                  color="primary"
                  class="ml-3 text-none px-10"
                  :loading="loadingquot"
                  outlined
                  rounded
                  :disabled="loadingpropuesta"
                  @click="GenerateQuotation(Infor)">
                  Descargar simulación
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </div>

        <!-- <v-tabs-items v-model="tab">
          <v-tab-item value="tab-1" class="pa-3">
            <SecureDetail :secure="Infor.coberturas_exclusiones" />
          </v-tab-item>
        </v-tabs-items> -->
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import SecureDetail from "@/components/Cards/Secure-detail-card.vue";
import cardPlan from "@/components/Cards/Plan-card";

export default {
  name: "ModalDetallePropuesta",
  props: {
    loadingquot: {
      type: Boolean,
      default: false,
    },
    loadShare: {
      type: Boolean,
      default: false,
    },
    open: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Object,
      default: null,
    },
    showQuoteActions: {
      type: Boolean,
      default: true,
    },
  },

  components: {
    // SecureDetail,
    cardPlan,
  },
  methods: {
    GenerateQuotation(item) {
      this.$emit("download", item);
    },
    sendQuotation(item) {
      this.$emit("share", item);
    },
  },
  computed: {
    coberturas() {
      return (this.selected?.coberturas_exclusiones ?? []).filter(
        (item) => item.tipo === "C"
      );
    },
    exclusiones() {
      return (this.selected?.coberturas_exclusiones ?? []).filter(
        (item) => item.tipo !== "C"
      );
    },
  },
};
</script>
<style scoped>
@import "../../assets/CSS/ServiceStyle.css";
.cardTitle {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
}
.card {
  overflow-y: hidden !important;
  border-radius: 10px;
}
</style>
