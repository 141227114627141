import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

export default {
  namespaced: true,
  state: {
    ensured: {
      documentType: null,
      documentNumber: null,
      name: null,
      giro: null,
      commercialActivity: null,
      socialReason: null,
      lastName: null,
      secondLastName: null,
      birthDate: null,
      gender: null,
      email: null,
      phone: null,
      commune: null,
      region: null,
      province: null,
      department: null,
      district: null,
      address: null,
      number: null,
      depto: null,
      ensuredInsurance: null,
    },
  },
  actions,
  mutations,
  getters,
};
