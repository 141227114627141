import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import vueRut from "vue-rut";
import moment from "moment";
import axios from "axios";
import { rutValidator } from "vue-dni";
import { VueMaskDirective, VueMaskFilter } from "v-mask";
import dateformat from "dateformat";
import "./components/global-components";
import "vuetify/dist/vuetify.min.css";
import "@mdi/font/css/materialdesignicons.css";

import { version } from "../package.json";
import i18n from "./i18n/index.js";
import VueCryptojs from "vue-cryptojs";

dateformat.i18n = {
  dayNames: [
    "Dom",
    "Lun",
    "Mar",
    "Mie",
    "Jue",
    "Vie",
    "Sab",
    "Domingo",
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sabado",
  ],
  monthNames: [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ],
  timeNames: ["a", "p", "am", "pm", "A", "P", "AM", "PM"],
};

Vue.directive("rut", rutValidator);
Vue.filter("formatDate", function (value, type) {
  let dateFormat;
  if (value) {
    if (type !== "doc") {
      dateFormat = moment(new Date(value)).format("DD/MM/YYYY");
    } else {
      dateFormat = dateformat(new Date(value), "d mmmm yyyy h:MM:ss TT");
    }
  }
  return dateFormat;
});

Vue.filter("formatDatePay", function (value) {
  let dateFormatPay;
  if (value) {
    dateFormatPay = dateformat(new Date(value), "d mmmm yyyy");
  }
  return dateFormatPay;
});

const moneyFormat = (val) => {
  const formatClp = Intl.NumberFormat("es-CL", {
    style: "currency",
    currency: "CLP",
    currencyDisplay: "narrowSymbol",
    // maximumFractionDigits: 2,
  });

  return formatClp.format(val);
};

Vue.filter("moneyFormat", moneyFormat);

Vue.filter("UFPrice", function (value) {
  let v = "";
  if (typeof value == "string") {
    v = value.replace(".", ",");
  } else {
    const t = value > 0 ? value.toFixed(2) : value;
    v = t.toString().replace(".", ",");
  }
  return `UF ${v}`;
});

Vue.filter("formatDateDoc", function (value) {
  let formatDateDoc;
  if (value) {
    formatDateDoc = dateformat(
      new Date(value),
      "dd, mmmm dN, yyyy, h:MM:ss TT"
    );
  }
  return formatDateDoc;
});

Vue.filter("formatNumberDate", function (value) {
  return value.length >= 8
    ? `${value.substr(0, 2)}/${value.substr(2, 2)}/${value.substr(4)}`
    : value;
});

Vue.filter("formatDateDocTwo", function (value) {
  let formatDateDoc;
  if (value) {
    formatDateDoc = dateformat(new Date(value), "dd mmmm  yyyy");
  }
  return formatDateDoc;
});

Vue.filter("phone", function (phone) {
  return phone
    .replace(/[^0-9]/g, "")
    .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
});

Vue.filter("formatBytes", function (bytes, decimals = 2) {
  if (bytes === 0) {
    return "0 Bytes";
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
});

Vue.filter("formatDateUser", function (value) {
  let formarDateUser;
  if (value) {
    formarDateUser = dateformat(new Date(value), "dddd d mmmm  yyyy");
  }
  return formarDateUser;
});

Vue.filter("formatHourUser", function (value) {
  let formatHouruser;
  if (value) {
    formatHouruser = dateformat(new Date(value), "hh:MM TT");
  }
  return formatHouruser;
});

Vue.filter("rutFormat", function (rut) {
  let rutFormat;
  if (rut) {
    rutFormat = `${rut.slice(-4, -1)}-${rut.substr(rut.length - 1)}`;
    for (let i = 4; i < rut.length; i += 3) {
      rutFormat = `${rut.slice(-3 - i, -i)}.${rutFormat}`;
    }
  }
  return rutFormat;
});
Vue.filter("VMask", VueMaskFilter);
Vue.directive("mask", VueMaskDirective);

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
axios.defaults.headers.common["x-mclick-key"] = process.env.VUE_APP_API_TOKEN;
Vue.config.productionTip = false;

Vue.use(vuetify, {
  iconfont: "mdi",
});
Vue.component("pulse-loader", require("vue-spinner/src/PulseLoader.vue"));
Vue.use(vueRut);
Vue.use(VueCryptojs);
Vue.use(i18n);
new Vue({
  i18n,
  router,
  store,
  vuetify,
  created() {
    this.$store.commit("setVersion", version);

    const userString = sessionStorage.getItem("user");
    const userToken = sessionStorage.getItem("token");

    if (userString) {
      const userData = JSON.parse(userString);
      this.$store.commit("SET_USERDATA", userData);
    }
    if (userToken) {
      this.$store.commit("REFRESH_TOKEN", userToken);
    }

    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          this.$store.dispatch("logout");
        }

        return Promise.reject(error);
      }
    );
  },
  render: (h) => h(App),
}).$mount("#app");
