const mutations = {
  setComunas(state, comunas) {
    state.comunas = comunas;
  },
  setRegiones(state, regiones) {
    state.regiones = regiones;
  },
  setProvincias(state, provincias) {
    state.provincias = provincias;
  },
  setTipoDocumentos(state, documentos) {
    state.tipo_documentos = documentos;
  },
  setTipoPersonas(state, tipo_personas) {
    state.tipo_personas = tipo_personas;
  },
  setLoading(state, loading) {
    state.loadingData = loading;
  },
  resetData(state) {
    state.regiones = null;
    state.provincias = null;
    state.comunas = null;
    state.tipo_documentos = null;
    state.tipo_personas = null;
    state.loadingData = true;
  },
};

export default mutations;
